import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ListOfAdminDiv, MainDiv, ShowItemsAdmin } from "./styled";
import { ListCommands } from "../list_commands";
import { AddService } from "../add_service";
import { AddCourse } from "../add_courses";
import { AllCourses } from "../all-courses";
import { AllServices } from "../all_services";
import { AllReviews } from "../all_reviews";
import { AddMember } from "../add_team_member";
import { AllMembers } from "../all_members";
import { AddSoonCourse } from "../add-soon-course";
import { AllSoonCourses } from "../all-soon-courses";

export const AdminPage = () => {
  const [admin, setAdmin] = useState();

  const [commands, setCommands] = useState([
    {
      command: "Ծառայություններ",
      checked: true,
      request: "https://server.rsoft.am/all-services",
    },
    {
      command: "Ավելացնել ծառայություն",
      checked: false,
      request: "https://server.rsoft.am/addService",
    },
    {
      command: "Բոլոր դասընթացները",
      checked: false,
      request: "https://server.rsoft.am/all-courses",
    },
    {
      command: "Ավելացնել դասընթաց",
      checked: false,
      request: "https://server.rsoft.am/add-new-course",
    },
    {
      command: "Մեր Թիմը",
      checked: false,
      request: "https://server.rsoft.am/add-new-course",
    },
    {
      command: "Ավելացնել թիմի անդամ",
      checked: false,
      request: "https://server.rsoft.am/add-new-course",
    },
    {
      command: "Կարծիքներ",
      checked: false,
      request: "https://server.rsoft.am/add-new-course",
    },
    {
      command: "Ավելացնել շուտով սպասվող դասընթաց",
      checked: false,
      request: "https://server.rsoft.am/add-new-course",
    },
    {
      command: "Շուտով սպասվող դասընթացներ",
      checked: false,
      request: "https://server.rsoft.am/add-new-course",
    },
  ]);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    console.log(location);
    if (!localStorage.getItem("admin")) {
      navigate("/admin");
    }
    if (location.state === null) {
      navigate("/admin");
    } else {
      setAdmin(location.state.user);
    }
  }, [location.pathname]);

  return (
    <MainDiv>
      <ListOfAdminDiv>
        <ListCommands commands={commands} setCommands={setCommands} />
      </ListOfAdminDiv>
      <ShowItemsAdmin>
        {commands[0].checked && <AllServices />}
        {commands[1].checked && <AddService />}
        {commands[2].checked && <AllCourses />}
        {commands[3].checked && <AddCourse />}
        {commands[4].checked && <AllMembers />}
        {commands[5].checked && <AddMember />}
        {commands[6].checked && <AllReviews />}
        {commands[7].checked && <AddSoonCourse />}
        {commands[8].checked && <AllSoonCourses />}
      </ShowItemsAdmin>
    </MainDiv>
  );
};
