import React from "react";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import { FourParts, MainDiv, Numbers, Texts } from "./styled";

const AnimatedNumber = ({ end, style }) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Анимация срабатывает только один раз
    threshold: 0.1, // Процент видимости элемента для срабатывания
  });

  return (
    <Numbers ref={ref} style={style} className={inView ? "fadeIn" : ""}>
      {inView ? <CountUp start={0} end={end} duration={2} /> : "0"}+
    </Numbers>
  );
};

export const Stats = () => {
  return (
    <MainDiv>
      <FourParts>
        <AnimatedNumber end={100} />
        <Texts>Ուսանողներ</Texts>
      </FourParts>
      <FourParts>
        <AnimatedNumber end={30} style={{ color: "white" }} />
        <Texts>Աշխատանքի անցած ուսանողներ</Texts>
      </FourParts>
      <FourParts>
        <AnimatedNumber end={27} />
        <Texts>Պրակտիկանտներ</Texts>
      </FourParts>
      <FourParts>
        <AnimatedNumber end={4} />
        <Texts>Մեզ մոտ աշխատող ուսանողներ</Texts>
      </FourParts>
    </MainDiv>
  );
};
