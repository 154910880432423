import React, { useState, useEffect } from "react";
import { Card, ContainerDiv, ImgBox, ImgL, PD, CH1, H2name } from "./styled";
import "./ser.css";

export const Services = () => {
  const [services, setServices] = useState([]);
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    async function getAllServices() {
      try {
        const request = await fetch("https://server.rsoft.am/all-services");
        if (!request.ok) return;
        const response = await request.json();
        console.log(response, "jhgfd");
        setServices(response.services);
      } catch (err) {
        console.error(err);
      }
    }
    getAllServices();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const part2Element = document.getElementById("part2");
      if (part2Element) {
        const rect = part2Element.getBoundingClientRect();
        if (rect.top < window.innerHeight) {
          setAnimate(true);
        } else {
          setAnimate(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <ContainerDiv>
        <CH1>Մեր Ծառայությունները</CH1>
        {services?.map((e, i) => {
          return (
            <Card key={e + i} to={i == 0 ? "/courses" : "/web-app"}>
              <ImgBox>
                <ImgL src={`https://server.rsoft.am/uploads/${e.img}`} />
              </ImgBox>
              <PD>{e.description}</PD>
              <H2name>{e.name}</H2name>
            </Card>
          );
        })}
      </ContainerDiv>
      <br />
      <br />
    </>
  );
};
