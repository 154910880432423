import React, { useEffect, useState } from "react";
import {
  LessonCard,
  LessonLogoPart,
  LessonsMain,
  MainDiv,
  LesonLogo,
  TextDiv,
  LessonNameDiv,
  LessonDesc,
} from "./styled";

export const AllMembers = () => {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    async function getAllCourses() {
      try {
        const request = await fetch("https://server.rsoft.am/all-team-members");
        if (!request.ok) return;
        const response = await request.json();
        setCourses(response.team);
        console.log(courses);
      } catch (err) {
        console.error(err);
      }
    }
    getAllCourses();
  }, []);

  return (
    <MainDiv>
      <TextDiv>Մեր թիմը</TextDiv>
      <LessonsMain>
        {courses?.length > 0 ? (
          courses.map((member, index) => (
            <LessonCard key={index}>
              <LessonLogoPart>
                <LesonLogo
                  src={`https://server.rsoft.am/uploads/${member.img}`}
                  width={"50%"}
                  height={"90%"}
                />
              </LessonLogoPart>
              <LessonNameDiv>
                {member.name} {member.lastName}
              </LessonNameDiv>
              <LessonDesc>{member.position}</LessonDesc>

              <LessonDesc>{member.description}</LessonDesc>
            </LessonCard>
          ))
        ) : (
          <div>No members available</div>
        )}
      </LessonsMain>
    </MainDiv>
  );
};
