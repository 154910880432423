import styled from "styled-components";

export const MainDiv = styled.form`
  width: 100%;
  height: 100vh;
  background-color: #24b7ff;
`;

export const TextDiv = styled.div`
  width: 100%;
  height: 35vh;
  text-align: center;
  font-size: 47px;
  font-weight: bold;
  display: flex;
  align-items: end;
  justify-content: center;
`;

export const FormBig = styled.div`
  width: 100%;
  height: 60vh;
  text-align: center;
  font-size: 47px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  padding-top: 20px;
`;

export const FormDiv = styled.div`
  width: 260px;
  height: 230px;
  background-color: white;
  border-radius: 10px;
  position: relative;
`;

export const LoginInput = styled.input`
  width: 90%;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #24b7ff;
  outline: #24b7ff;
  padding-left: 5px;
  font-size: 14px;
  margin-top: 22px;
`;

export const LoginButton = styled.button`
  width: 90%;
  height: 40px;
  border-radius: 10px;
  background-color: #24b7ff;
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 15px;
`;

export const ErrorMessage = styled.div`
  width: 100%;
  height: 20px;
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  padding-left: 20px;
  color: red;
  position: absolute;
  top: 63px;
`;

export const ErrorMessage2 = styled.div`
  width: 100%;
  height: 20px;
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  padding-left: 20px;
  color: red;
  position: absolute;
  top: 123px;
`;
