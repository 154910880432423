import React from "react";
import {
  AddBtn,
  DescriptionInput,
  FileBtn,
  FileInput,
  FormOfInputs,
  FormPart,
  InputInfo,
  MainDiv,
  NameInput,
  TextDiv,
  TreePArts,
} from "./styled";
import { useForm } from "react-hook-form";

export const AddMember = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onProductsSubmit = async (data) => {
    if (data.name === "" || data.lastName === "" || data.position === "") {
      alert("Please fill in all fields");
      return;
    }
    console.log(data);
    if (!data.img[0]) {
      alert("No image selected");
      return;
    }
    if (data.img[0]?.size > 10 * 1024 * 1024) {
      alert("Image must be smaller than 10MB");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("lastName", data.lastName);
      formData.append("position", data.position);
      formData.append("description", data.description);
      formData.append("img", data.img[0]);

      const req = await fetch("https://server.rsoft.am/addStaffMember", {
        method: "POST",
        body: formData,
      });

      if (req.ok) {
        alert("Member added successfully");
      } else {
        alert("Something went wrong");
      }

      const res = await req.json();
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <MainDiv
      onSubmit={handleSubmit(onProductsSubmit)}
      encType="multipart/form-data"
    >
      <TextDiv>Նոր թիմի անդամ</TextDiv>
      <FormPart>
        <FormOfInputs>
          <TreePArts>
            <InputInfo>Անուն</InputInfo>
            <NameInput {...register("name")} />
          </TreePArts>
          <TreePArts>
            <InputInfo>Ազգանուն</InputInfo>
            <NameInput {...register("lastName")} />
          </TreePArts>
          <TreePArts>
            <InputInfo>Հաստիք</InputInfo>
            <NameInput {...register("position")} />
          </TreePArts>
        </FormOfInputs>

        <InputInfo style={{ paddingLeft: "12px" }}>Description</InputInfo>
        <DescriptionInput {...register("description")} />
        <FileBtn>Select Image</FileBtn>
        <FileInput type="file" accept="image/*" {...register("img")} />
        <AddBtn>Ավելացնել աշխատող</AddBtn>
      </FormPart>
    </MainDiv>
  );
};
