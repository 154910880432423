import React from 'react'
import proj1 from "./image/pch.jpg"
import proj2 from "./image/prs.jpg"
import proj4 from "./image/prs.png"

import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, EffectCoverflow, Autoplay } from 'swiper/modules'

import 'swiper/css';
import 'swiper/css/bundle';

import './Carousel.css'

const slider = [
    {
        title: "Ծրագրավորում երեխաների համար",
        description: "Երեխաների համար վեբ ծրագրավորման մեր գրավիչ դասերը օգնում են երեխաներին զարգանալ և բացահայտել թվային աշխարհը: Միացե՛ք մեզ՝ հզորացնելու ձեր երեխայի թվային ճանապարհորդությունը:",
        url: proj1
    },
    {
        title: "Կայքերի պատրաստում",
        description: "Վստահեք ձեր բիզնեսի առաջխաղացումը մեզ։ Մենք առաջակում ենք յուրահատուկ վեբ և մոբայլ հավելավածների պատրաստում որոնք կոգնեն ձեր բիզնեսը հասանելի դարձնել ամբողջ աշխարհի։ն",
        url: proj2
    },
    {
        title: "Մասնագիտացված դասընթացներ",
        description: "Սկսեք ձեր կարիերայի առաջին քայլերը մեր թիմի հետ։ Մեծահասակների համար մեր մասնագիտական ​​վեբ ծրագրավորման դասընթացները առաջարկում են թե պրակտիկ և թե տեսական գիտելիքների մեծ պաշար։",
        url: proj4
    },
]

const Carousel = () => {
    return (
        <div className='carousel'>
            <div>
                <div className='carousel-content'>
                    <h2>Բարի գալուստ R'SOFT</h2>
                    <hr />
                    <p>R'SOFT-ում կարող եք գտնել բարձրակարգ վեբ և մոբայլ </p>
                    <p>հավելվածների ծառայություններ և գերազանց ուսուցում :</p>
                </div>
            </div>

            <Swiper
                className='myswiper'
                modules={[Pagination, EffectCoverflow, Autoplay]}
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                coverflowEffect={{
                    rotate: 0,
                    stretch: 0,
                    depth: 100,
                    modifier: 3,
                    slideShadows: true
                }}
                loop={true}
                pagination={{ clickable: true }}

                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false
                }}
                breakpoints={{
                    640: {
                        slidesPerView: 2
                    },
                    768: {
                        slidesPerView: 1
                    },
                    1024: {
                        slidesPerView: 2
                    },
                    1560: {
                        slidesPerView: 3
                    },
                }}

            >
                {
                    slider.map(data => (
                        <SwiperSlide style={{ backgroundColor: "white", backgroundImage: `url(${data.url})` }} className="myswiper-slider">
                            <div>
                                <h2>{data.title}</h2>
                                <p>{data.description}</p>
                                <a style={{ opacity: "0", marginTop: "-30px" }} target="_blank" className='slider-btn'>explore</a>
                            </div>
                        </SwiperSlide>
                    ))
                }
            </Swiper>


        </div>
    )
}

export { Carousel }