import styled from "styled-components";

export const MainDiv = styled.div`
  width: 100%;
`;

export const ListOfAdminDiv = styled.div`
  width: 25%;
  height: 100vh;
  background-color: #24b7ff;
  border-right: 1px solid black;
  position: fixed;
  float: left;
`;

export const ShowItemsAdmin = styled.div`
  width: 75%;
  float: right;
`;
