import React, { useEffect, useState } from "react";
import {
  LessonCard,
  LessonLogoPart,
  LessonsMain,
  MainDiv,
  LesonLogo,
  TextDiv,
  LessonNameDiv,
  LessonDesc,
} from "./styled";

export const AllServices = () => {
  const [services, setServices] = useState([]);

  useEffect(() => {
    async function getAllServices() {
      try {
        const request = await fetch("https://server.rsoft.am/all-services");
        if (!request.ok) return;
        const response = await request.json();
        console.log(response, "jhgfd");
        setServices(response.services);
      } catch (err) {
        console.error(err);
      }
    }
    getAllServices();
  }, []);

  return (
    <MainDiv>
      <TextDiv>Բոլոր Ծառայությունները</TextDiv>
      <LessonsMain>
        {services.length > 0 ? (
          services.map((service, index) => (
            <LessonCard key={index}>
              <LessonLogoPart>
                <LesonLogo
                  src={`https://server.rsoft.am/uploads/${service.img}`}
                  width={"100%"}
                  height={"100%"}
                />
              </LessonLogoPart>
              <LessonNameDiv>{service.name}</LessonNameDiv>
              <LessonDesc>{service.description}</LessonDesc>
            </LessonCard>
          ))
        ) : (
          <div>No services available</div>
        )}
      </LessonsMain>
    </MainDiv>
  );
};
