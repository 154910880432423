import React from "react";
import { MainDiv } from "./styled";
import { Carousel, CoursesComp, Services, Stats } from "../../components";
import { Reviews } from "../../components/reviews/App";

export const Home = () => {
  return (
    <MainDiv>
      <Carousel />
      <br />
      <Services />
      <Stats />
      <CoursesComp />
      <Reviews />
    </MainDiv>
  );
};
