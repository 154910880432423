import React from "react";
import { CommandDiv, MainDiv } from "./styled";

export const ListCommands = ({ commands, setCommands }) => {
  const checkOnClick = (i) => {
    setCommands((prev) => {
      const arr = prev.slice();
      arr.forEach((element) => {
        element.checked = false;
      });
      arr[i].checked = true;
      return arr;
    });
  };

  return (
    <MainDiv>
      {commands.map((element, index) => (
        <CommandDiv
          onClick={() => checkOnClick(index)}
          checked={element.checked}
          key={index}
        >
          {element.command}
        </CommandDiv>
      ))}
    </MainDiv>
  );
};
