import React, { useRef, useEffect } from "react";
import EcoMot from "../../assets/images/ecomot.jpg";
import RsoftWeb from "../../assets/images/rsoft.png";
import LaSport from "../../assets/images/la.png";
import AndHun from "../../assets/images/andhun.png";
import Mayyan from "../../assets/images/mayyan.png";
import "./works.css";

export const AnimWorks = () => {
  const sliderRef = useRef(null);
  const nextRef = useRef(null);
  const prevRef = useRef(null);

  useEffect(() => {
    const slider = sliderRef.current;
    const next = nextRef.current;
    const prev = prevRef.current;

    const handleNextClick = () => {
      const slides = slider.querySelectorAll(".slidesWeb");
      slider.appendChild(slides[0]);
    };

    const handlePrevClick = () => {
      const slides = slider.querySelectorAll(".slidesWeb");
      slider.prepend(slides[slides.length - 1]);
    };

    next.addEventListener("click", handleNextClick);
    prev.addEventListener("click", handlePrevClick);

    return () => {
      next.removeEventListener("click", handleNextClick);
      prev.removeEventListener("click", handlePrevClick);
    };
  }, []);

  return (
    <>
      <div className="containerWeb">
        <div className="sliderWeb" ref={sliderRef}>
          <div
            className="slidesWeb"
            style={{ backgroundImage: `url(${Mayyan})` }}
          >
            <div className="contentWeb">
              <h2><a target="_blank" href="https://mayyanart.com/" style={{ color: "white" }}>Mayyan</a></h2>
            </div>
          </div>
          <div
            className="slidesWeb"
            style={{ backgroundImage: `url(${EcoMot})` }}
          >
            <div className="contentWeb">
              <h2><a target="_blank" href="https://www.ecomotors.am/" style={{ color: "white" }}> Eco Motors </a></h2>

            </div>
          </div>

          <div
            className="slidesWeb"
            style={{ backgroundImage: `url(${LaSport})` }}
          >
            <div className="contentWeb">
              <h2><a target="_blank" href="https://lasportscards.net/" style={{ color: "white" }}>LA Sport Cards</a></h2>

            </div>
          </div>
          <div
            className="slidesWeb"
            style={{ backgroundImage: `url(${AndHun})` }}
          >
            <div className="contentWeb">
              <h2><a target="_blank" href="https://www.andranikhunanian.com/" style={{ color: "white" }}>Andranik Hunanyan</a></h2>

            </div>
          </div>


        </div>
        <div className="buttons">
          <span className="leftw" ref={prevRef}></span>
          <span className="rightw" ref={nextRef}></span>
        </div>
      </div>
    </>
  );
};
