import { Link } from "react-router-dom";
import styled from "styled-components";

export const MainMenu = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  z-index: 99999999999;

  background-color: white;
`;

export const LogoDiv = styled(Link)`
  width: 130px;
  height: 60px;
  float: left;
  font-family: Roboto, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 30px;
  text-decoration: none;
  padding-top: 14px;
  text-align: center;
  color: #296ebd;
`;

export const SpanR = styled.span`
  color: black;
`;

export const DepartmentsContainer = styled.div`
  width: 650px;
  height: 60px;
  @media (max-width: 750px) {
    display: none;
  }
`;

export const Departments = styled(Link)`
  width: 25%;
  height: 60px;
  float: left;
  font-family: Roboto, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 14.5px;
  text-decoration: none;
  padding-top: 18px;
  text-align: center;
  color: ${({ $active }) => ($active === true ? "#296ebd" : "black")};
  &:hover {
    color: #296ebd;
  }
`;
export const DepartmentsResp = styled(Link)`
  width: 100%;
  height: 60px;
  font-family: Roboto, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 19.5px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ $active }) => ($active === true ? "white" : "silver")};
  &:hover {
    color: white;
  }
`;
export const LanguageRamka = styled.div`
  width: 70px;
  height: 37px;
  background-color: silver;
  border-radius: 10px;
  margin-right: 10px;
  margin-top: 12px;
  padding-top: 4px;
  font-family: Roboto, Helvetica, sans-serif;
  font-weight: bold;
`;

export const LanguageDiv = styled.div`
  width: 70px;
  height: 105px;
  background-color: #edebeb;
  cursor: pointer;
  border-radius: 7px;
  padding-top: 2px;
`;

export const HeaderLanguage = styled.div`
  width: 70px;
  height: 30px;
  border-radius: 7px;
  cursor: pointer;
  margin-top: 5px;
  padding-left: 5px;
`;
export const LangImg = styled.img`
  width: 20px;
  float: left;
  height: 20px;
  margin-left: 4px;
  border-radius: 50%;
`;
export const LangText = styled.span`
  width: 17px;
  float: left;
  margin-left: 10px;
`;

export const IconDiv = styled.div`
  width: 60px;
  height: 60px;
  justify-content: center;
  align-items: center;
  display: none;
  color: black;
  font-size: 35px;
  @media (max-width: 750px) {
    display: flex;
  }
`;

export const RespMenu = styled.div`
  width: 100%;
  height: 50vh;
  position: fixed;
  transition: 0.5s;
  top: ${({ value }) => (value ? "60px" : "-50vh")};
  background: rgb(27,105,225);
  background: linear-gradient(101deg, rgba(27,105,225,1) 27%, rgba(15,129,233,1) 62%, rgba(5,167,234,1) 96%);
  z-index: 99999999999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
