import React, { useEffect } from "react";
import styled from "styled-components";

const StarsCanvas = styled.canvas`
  background: ${(props) => props.backgroundColor || "#477ab9"};
  width: 100%;
  height: 100vh;
  transition: all 0.4s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;

  &:hover {
    background: ${(props) => props.hoverColor || "#3d689c"};
  }
`;

const BackgroundGif = ({ backgroundColor, hoverColor }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://atuin.ru/js/art/stars.js";
    script.type = "text/javascript";
    script.onload = () => {
      if (window.initStars) {
        window.initStars();
      }
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <StarsCanvas
      id="stars"
      backgroundColor={backgroundColor}
      hoverColor={hoverColor}
    />
  );
};

export { BackgroundGif };
