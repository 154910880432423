import { Link } from "react-router-dom";
import styled from "styled-components";

export const MainDiv = styled.div`
  width: 100%;
  @media (max-width: 750px) {
    height: 2290px;
  }
`;

export const DepartementsMain = styled.div`
  width: 100%;
  height: 580px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: 1110px) {
    font-size: 10.5px;
  }
  @media (max-width: 750px) {
    height: 1940px;
    margin-top: 50px;
    font-size: 23px;
    line-height: 25px;
  }
`;

export const Title = styled.div`
  width: 100%;
  height: 50px;
  text-align: center;
  font-size: 45px;
  font-weight: bold;
  padding-top: 10px;
  color: white;
  @media(max-width: 500px){font-size: 27px;}

`;
export const TitleSite = styled.div`
  width: 100%;
  height: 50px;
  text-align: center;
  font-size: 45px;
  font-weight: bold;
  padding-top: 10px;
  color: white;
  @media(max-width: 800px){display: none;}

`;

export const WebCard = styled.div`
  width: 27%;
  height: 490px;
  position: relative;
  border-radius: 20px;
  background-color: ${({ type }) => {
    return (
      (type === "Simple" && "#1eb5ff") ||
      (type === "Medium" && "#0698ff") ||
      (type === "Premium" && "#0084ff")
    );
  }};
  background: rgb(2, 0, 36);
  background: linear-gradient(
    118deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(9, 21, 121, 0.5662640056022409) 0%,
    rgba(0, 212, 255, 1) 100%
  );
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  @media (max-width: 850px) {
    width: 32%;
  }
  @media (max-width: 750px) {
    width: 90%;
    height: 590px;
  }
`;

export const TitleCard = styled.div`
  width: 100%;
  height: 90px;
  text-align: center;
  font-weight: bold;
  display: flex;
  justify-content: center;
  padding-top: 30px;
  font-size: 40px;
`;

export const ProportiesPart = styled.div`
  width: 100%;
  height: 30px;
  padding-left: 10px;
  color: white;
  margin-top: 2px;
  @media (max-width: 750px) {
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 22px;
  height: 4 0px;

  }
  @media (max-width: 500px) {
    font-size: 14px;
  }
  @media (max-width: 300px) {
    font-size: 13px;
  }
`;

export const ApplyButtonPart = styled.div`
  width: 100%;
  height: 130px;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

export const PriceDiv = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
`;

export const ApplyButton = styled(Link)`
  text-decoration: none;

  width: 120px;
  height: 50px;
  background-color: white;
  color: ${({ type }) => {
    return (
      (type === "Simple" && "#b5edff") ||
      (type === "Medium" && "#48d2ff") ||
      (type === "Premium" && "#0698ff")
    );
  }};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  color: black;
  border-radius: 40px;
  @media (max-width: 500px) {
    font-size: 14px;
  }
`;


export const OpenedDiv = styled.div`
  width: 100%;
  height: 100vh;
  background: #0000005e;
  position: fixed;
  display: flex;
  padding-top: 10px;
  justify-content: center;
  z-index: 999;
`;

export const ApplyBlankDiv = styled.div`
  width: 40%;
  height: 87vh;
  background-color: white;
  border-radius: 20px;
  z-index: 999;
  overflow: hidden;
  @media (max-width: 1010px) {
    width: 60%;
  }
  @media (max-width: 698px) {
    width: 80%;
  }
  @media (max-width: 522px) {
    width: 90%;
  }
`;

export const TtitleBlank = styled.div`
  width: 100%;
  height: 50px;
  padding: 15px;
  font-weight: bold;
  font-size: 17px;
  color: #296ebd;
`;

export const CloseBlank = styled.div`
  width: 30px;
  height: 30px;
  float: right;
  font-weight: bold;
  cursor: pointer;
  font-size: 27px;
  color: black;
`;

export const InfoParts = styled.div`
  width: 100%;
  height: 75px;
`;

export const InfoName = styled.div`
  width: 100%;
  height: 30px;
  font-weight: bold;
  padding-left: 15px;
  padding-top: 5px;
`;

export const InfoInp = styled.input`
  width: 94%;
  height: 35px;
  border-radius: 10px;
  border: 3px solid #296ebd;
  margin-left: 15px;
  margin-top: 5px;
  outline: none;
  padding-left: 10px;
`;

export const FinishApply = styled.button`
  border: none;
  outline: none;
  width: 94%;
  height: 40px;
  background-color: #296ebd;
  margin-left: 15px;
  color: white;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 70px;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 1px;
`;


