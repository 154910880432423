import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Bg from "../../assets/images/bg.png";
import Mard1 from "../../assets/images/pic-1.jpg";

// Styled Components
const StyledHeader = styled.header`
  min-height: 100vh;
  display: flex;
`;

const StyledContainer = styled.div`
  width: 100%;
  margin: auto;
  padding: 1rem;
  @media (max-width: 1200px) {
    gap: 3rem;
  }

  @media (max-width: 900px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const StyledContainerRight = styled.div`
  width: 100%;
  display: grid;
  gap: 2rem;

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 750px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const StyledCard = styled.div`
  padding: 2rem;
  display: flex;
  align-items: flex-start;
  gap: 2rem;
  background-color: var(--white);
  border-radius: 1rem;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  position: relative;
`;

const StyledCardImage = styled.img`
  max-width: 75px;
  border-radius: 100%;
`;

const StyledCardContent = styled.div`
  display: flex;
  gap: 1rem;
`;

const StyledCardDetails = styled.div`
  p {
    font-style: italic;
    color: var(--text-dark);
    margin-bottom: 1rem;
  }

  h4 {
    text-align: right;
    color: var(--primary-color);
    font-size: 1rem;
    font-weight: 500;
  }
`;

const DelBtn = styled.div`
  width: 30px;
  height: 30px;
  float: right;
  position: absolute;
  right: 40px;
  background-color: red;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
`;

const CheckBtn = styled.div`
  width: 30px;
  height: 30px;
  float: right;
  position: absolute;
  right: 40px;
  top: 70px;
  background-color: red;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
`;

// React Component
export const AllReviews = () => {
  const [reviews, setReviews] = useState();
  const [updComponent, setUpdComponent] = useState(false);

  useEffect(() => {
    try {
      const getRev = async () => {
        const request = await fetch("https://server.rsoft.am/reviewsAllAdmin");
        const response = await request.json();
        setReviews(response.reverse());
      };
      getRev();
    } catch (err) {
      console.log(err);
    }
  }, [updComponent]);

  const delReview = async (id) => {
    try {
      const request = await fetch(`https://server.rsoft.am/deleteReview/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });
      await request.json();
      setUpdComponent(!updComponent);
    } catch (err) {
      console.log(err);
    }
  };

  const checkRev = async (id) => {
    try {
      const request = await fetch(`https://server.rsoft.am/confirmReview/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
      });
      await request.json();
      setUpdComponent(!updComponent);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <StyledHeader>
      <StyledContainer>
        <StyledContainerRight>
          {reviews?.map((e, i) => {
            return (
              <StyledCard key={e + i}>
                <StyledCardImage src={Mard1} alt="user" />
                <StyledCardContent>
                  <StyledCardDetails>
                    <p>{e.description}</p>
                    <h4>
                      - {e.name} {e.lastName}
                    </h4>

                    {!e.confirmed && (
                      <h5
                        style={{
                          width: "200px",
                          position: "relative",
                          top: "20px",
                        }}
                      >
                        hastatvac che
                      </h5>
                    )}
                  </StyledCardDetails>
                </StyledCardContent>
                <DelBtn onClick={() => delReview(e._id)}>X</DelBtn>
                {!e.confirmed && (
                  <CheckBtn onClick={() => checkRev(e._id)}>✔</CheckBtn>
                )}
              </StyledCard>
            );
          })}
        </StyledContainerRight>
      </StyledContainer>
    </StyledHeader>
  );
};
