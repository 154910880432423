import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Apply,
  ApplyBlankDiv,
  CloseBlank,
  DetailPart,
  Detailings,
  FinishApply,
  Halfs,
  InfoInp,
  InfoName,
  InfoParts,
  MainDiv,
  MoreDiv,
  OpenedDiv,
  TitlePart,
  TtitleBlank,
} from "./styled";
import ComingSoonPic from "../../assets/images/com-soon.png";
import { IoCloseCircle } from "react-icons/io5";

export const ComingSoon = () => {
  const [selected, setSelected] = useState();
  const [apply, setApply] = useState(false);
  const params = useParams();

  useEffect(() => {
    const getCourseById = async () => {
      try {
        const req = await fetch(
          `https://server.rsoft.am/all-courses-soon/${params.id}`
        );
        const res = await req.json();
        if (req.ok) {
          setSelected(res);
        }
        console.log(selected);
      } catch (err) {
        console.log(err);
      }
    };
    getCourseById();
  }, []);

  console.log(params);

  return (
    <>
      <MainDiv style={{ position: "relative" }}>
        {apply && (
          <OpenedDiv>
            <ApplyBlankDiv>
              <TtitleBlank>
                Լրացրեք տվյալները գրանցվելու համար{" "}
                <CloseBlank>
                  <IoCloseCircle onClick={() => setApply(false)} />
                </CloseBlank>
              </TtitleBlank>
              <InfoParts>
                <InfoName>
                  Անուն{" "}
                  <span style={{ fontSize: "21px", color: "red" }}>*</span>
                </InfoName>
                <InfoInp />
              </InfoParts>
              <InfoParts>
                <InfoName>
                  Ազգանուն{" "}
                  <span style={{ fontSize: "21px", color: "red" }}>*</span>
                </InfoName>
                <InfoInp />
              </InfoParts>
              <InfoParts>
                <InfoName>
                  Հեռախոսահամար{" "}
                  <span style={{ fontSize: "21px", color: "red" }}>*</span>
                </InfoName>
                <InfoInp />
              </InfoParts>
              <InfoParts>
                <InfoName>
                  Ընտրած դասընթաց{" "}
                  <span style={{ fontSize: "21px", color: "red" }}>*</span>
                </InfoName>
                <InfoInp />
              </InfoParts>
              <InfoParts>
                <InfoName>
                  Տարիք{" "}
                  <span style={{ fontSize: "21px", color: "red" }}>*</span>
                </InfoName>
                <InfoInp />
              </InfoParts>
              <FinishApply>Դիմել</FinishApply>
            </ApplyBlankDiv>
          </OpenedDiv>
        )}

        <TitlePart>{selected?.name}</TitlePart>
        <img
          src={ComingSoonPic}
          style={{
            width: "130px",
            position: "absolute",
            top: "72px",
            left: 0,
            right: 0,
            margin: "auto",
            transform: "rotate(-10deg)",
          }}
        />
        <MoreDiv>
          <Halfs
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={`https://server.rsoft.am/uploads/${selected?.img}`}
              width={"80%"}
            />
          </Halfs>
          <Halfs>
            <DetailPart>Տեղեկություն</DetailPart>
            <Detailings>
              Տևողություն՝{" "}
              <b style={{ marginLeft: "10px" }}>{selected?.duration}</b>
            </Detailings>
            <Detailings>
              Ամսեվճար՝ <b style={{ marginLeft: "10px" }}>{selected?.price}</b>{" "}
              Դ
            </Detailings>
            <Detailings>
              Մեկնարկ` <b style={{ marginLeft: "10px" }}>{selected?.start}</b>{" "}
            </Detailings>
            <Detailings>
              Ուսուցիչ`{" "}
              <span
                style={{
                  marginLeft: "10px",
                  cursor: "pointer",
                  textDecoration: "underline",
                  color: "#296ebd",
                }}
              >
                {selected?.teacher}
              </span>{" "}
            </Detailings>
            <p style={{ paddingLeft: "10px", marginTop: "10px" }}>
              {selected?.description}
            </p>
            <br />
            <Link to="/courses" style={{ margin: "10px" }}>
              Բոլոր դասընթացները
            </Link>{" "}
            <br />
            <Apply onClick={() => setApply(true)}>Դիմել</Apply>
          </Halfs>
        </MoreDiv>
      </MainDiv>
    </>
  );
};
