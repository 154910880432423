import React from "react";
import Review from "./Review";
import "./asd.css";

function Reviews() {
  return (
    <main style={{ paddingBottom: "20px" }}>
      <section className="container">
        <div children="title">
          <h2 style={{ color: "white" }}>Կարծիքներ մեր մասին</h2>
        </div>
        <br />
        <Review />
      </section>
    </main>
  );
}

export { Reviews };
