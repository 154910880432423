import React, { useEffect, useState } from "react";
import {
  LessonCard,
  LessonLogoPart,
  LessonsMain,
  MainDiv,
  LesonLogo,
  TextDiv,
  LessonNameDiv,
  LessonDesc,
} from "./styled";

export const AllCourses = () => {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    async function getAllCourses() {
      try {
        const request = await fetch("https://server.rsoft.am/all-courses");
        if (!request.ok) return;
        const response = await request.json();
        console.log(response, "jhgfd");
        setCourses(response.courses);
      } catch (err) {
        console.error(err);
      }
    }
    getAllCourses();
  }, []);

  return (
    <MainDiv>
      <TextDiv>Բոլոր դասընթացները</TextDiv>
      <LessonsMain>
        {courses.length > 0 ? (
          courses.map((course, index) => (
            <LessonCard key={index}>
              <LessonLogoPart>
                <LesonLogo
                  src={`https://server.rsoft.am/uploads/${course.img}`}
                  width={"50%"}
                  height={"90%"}
                />
              </LessonLogoPart>
              <LessonNameDiv>{course.name}</LessonNameDiv>
              <LessonDesc>{course.description}</LessonDesc>
            </LessonCard>
          ))
        ) : (
          <div>No courses available</div>
        )}
      </LessonsMain>
    </MainDiv>
  );
};
