import React, { useState } from "react";
import {
  ApplyBlankDiv,
  ApplyButton,
  ApplyButtonPart,
  CloseBlank,
  DepartementsMain,
  FinishApply,
  InfoInp,
  InfoName,
  InfoParts,
  MainDiv,
  OpenedDiv,
  PriceDiv,
  ProportiesPart,
  Title,
  TitleCard,
  TitleSite,
  TtitleBlank,
  WebCard,
} from "./styled";
import { IoCloseCircle } from "react-icons/io5";
import { AnimWorks } from "../../components/our works";
import { toast } from "react-toastify";
export const WebApp = () => {
  const patetner = [
    {
      type: "Simple",
      rules: [
        "Պատրաստի դիզայն",
        "3-4 էջ",
        "2 ամիս անվճար սպասարկում",
        "Ղեկավարման հասարակ վահանակ",
        "Մինչև 7 օր",
      ],
      price: "100.000",
    },
    {
      type: "Medium",
      rules: [
        "Անհատական դիզայն",
        "մինչև 7 էջ",
        "6 ամիս անվճար սպասարկում",
        "Ղեկավարման միջին վահանակ",
        "SEO օպտիմիզացիա",
        "Դոմեյն և հոիսթինգ",
        "Մինչև 15 օր",
      ],
      price: "250.000",
    },
    {
      type: "Premium",
      rules: [
        "Անհատական դիզայն",
        "Անթիվ էջեր",
        "1 տարի անվճար սպասարկում",
        "Ղեկավարման դետալային վահանակ",
        "SEO օպտիմիզացիա",
        "Դոմեյն և հոիսթինգ",
        "թարկմանություններ",
        "Կայքի քեշավորում",
        "մինչև 30 օր",
      ],
      price: "500.000",
    },
  ];
  const [docs, setDocs] = useState({
    anun: "",
    azganun: "",
    phone: "",
    selectedCourse: "",
    desc: "",
  });
  const [apply, setApply] = useState(false);

  const myChange = (e) => {
    const { value, name } = e.target;
    setDocs((prev) => {
      return { ...prev, [name]: value };
    });
  };
  const sendData = async () => {
    if (docs.anun == "" || docs.azganun == "" || docs.phone == "" || docs.desc == "") {
      return toast.warning("Լրացնել բաց թողնված դաշտը")
    } else {
      toast.success("Դուք հաջողությամբ գրանցվել եք")
      setApply(false)
      setDocs({
        anun: "",
        azganun: "",
        phone: "",
        desc: "",
      });
    }

    try {
      const req = await fetch("https://server.rsoft.am/get-web-app", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(docs),
      });
      const res = await req.json();
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <MainDiv>
      {apply && (
        <OpenedDiv>
          <ApplyBlankDiv>
            <TtitleBlank>
              Լրացրեք տվյալները գրանցվելու համար{" "}
              <CloseBlank>
                <IoCloseCircle onClick={() => setApply(false)} />
              </CloseBlank>
            </TtitleBlank>
            <InfoParts>
              <InfoName>
                Անուն <span style={{ fontSize: "21px", color: "red" }}>*</span>
              </InfoName>
              <InfoInp name="anun" value={docs.anun} onChange={myChange} />
            </InfoParts>
            <InfoParts>
              <InfoName>
                Ազգանուն{" "}
                <span style={{ fontSize: "21px", color: "red" }}>*</span>
              </InfoName>
              <InfoInp
                name="azganun"
                value={docs.azganun}
                onChange={myChange}
              />
            </InfoParts>
            <InfoParts>
              <InfoName>
                Հեռախոսահամար{" "}
                <span style={{ fontSize: "21px", color: "red" }}>*</span>
              </InfoName>
              <InfoInp
                type="number"
                name="phone"
                value={docs.phone}
                onChange={myChange}
              />
            </InfoParts>

            {/* <InfoParts>
              <InfoName>
                Ընտրած փաթեթ{" "}
                <span style={{ fontSize: "21px", color: "red" }}>*</span>
              </InfoName>
              <InfoInp value={docs.selectedCourse} />
            </InfoParts> */}
            <InfoParts>
              <InfoName>
                Կայքի բովանդակություն <span style={{ fontSize: "21px", color: "red" }}>*</span>
              </InfoName>
              <InfoInp
                name="desc"
                value={docs.desc}
                onChange={myChange}
                style={{height:"100px"}}
              />
            </InfoParts>
            <FinishApply onClick={sendData}>Դիմել</FinishApply>
          </ApplyBlankDiv>
        </OpenedDiv>
      )}
      <TitleSite>Մեր պատրաստած կայքերը</TitleSite>

      <AnimWorks />
      <br />
      <br />
      <Title>Վեբ-Կայքերի Պատրաստում</Title>
      <br />
      <br />
      <DepartementsMain>
        {patetner.map((e, i) => {
          return (
            <WebCard type={e.type} key={e + i}>
              <TitleCard>{e.type}</TitleCard>
              {e.rules.map((element, index) => (
                <ProportiesPart>✔ {element}</ProportiesPart>
              ))}
              <ApplyButtonPart type={e.type}>
                <PriceDiv>
                  սկսած՝{" "}
                  <span
                    style={{
                      fontSize: "27px",
                      marginLeft: "10px",
                    }}
                  >
                    {e.price}
                  </span>
                  <span style={{ marginLeft: "3px" }}> դրամ </span>
                </PriceDiv>
                <ApplyButton
                  // to={"https://www.instagram.com/rsoft.web/"}
                  // target="_blank"
                  onClick={() => {
                    setApply(true);
                    setDocs((prev) => {
                      return { ...prev, selectedCourse: e.type };
                    });
                  }}
                >
                  Դիմել
                </ApplyButton>
              </ApplyButtonPart>
            </WebCard>
          );
        })}
      </DepartementsMain>
    </MainDiv>
  );
};
