import { Link } from "react-router-dom";
import styled from "styled-components";

export const ContainerDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 60px;
  flex-wrap: wrap;
`;

export const ImgBox = styled.div`
  position: absolute;
  inset: 20px 20px 50px 20px;
  border-radius: 15px;
  background: #0267c0;
  transition: 0.5s;
  &::before {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: transparent;
    left: -30px;
    border-top-right-radius: 25px;
    transform: translateY(-100px);
    opacity: 0;
    box-shadow: 5px -5px 0 4px #0267c0;
    pointer-events: none;
    transition: 0.5s;
  }

  &::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    background: transparent;
    right: -30px;
    border-top-left-radius: 20px;
    transform: translateY(-100px);
    opacity: 0;
    box-shadow: -5px -5px 0 4px #0267c0;
    pointer-events: none;
    transition: 0.5s;
  }
`;

export const PD = styled.p`
  position: relative;
  padding: 40px;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-50px);
  transition: 0.5s;
`;

export const H2name = styled.h2`
  position: absolute;
  bottom: 8px;
  color: #0267c0;
  transition: 0.5s;
  font-weight: 600;
`;

export const CH1 = styled.h1`
  width: 100%;
  font-size: 45px;
  color: white;
  text-align: center;
  padding-top: 30px;
  @media (max-width: 570px) {
    font-size: 35px;
  }
  @media (max-width: 450px) {
    font-size: 30px;
  }
`;

export const Card = styled(Link)`
  position: relative;
  width: 320px;
  height: 250px;
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
  color: black;
  cursor: pointer;
  transition: 0.5s;
  &:hover {
    height: 350px;
  }

  &:hover ${ImgBox} {
    inset: -50px 60px 250px 60px;
    box-shadow: 0 0 0 10px #0267c0;
  }

  &:hover ${ImgBox}::before {
    opacity: 1;
    transform: translateY(50px);
  }

  &:hover ${ImgBox}::after {
    opacity: 1;
    transform: translateY(50px);
  }

  &:hover ${PD} {
    opacity: 1;
    visibility: visible;
    transform: translateY(40px);
  }

  &:hover ${H2name} {
    padding: 5px 25px;
    background: black;
    color: white;
    bottom: -25px;
    border-radius: 15px;
    box-shadow: 0 0 0 10px #0267c0;
  }
`;

export const ImgL = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
`;
