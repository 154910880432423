import React, { useLayoutEffect } from "react";
import {
  ErrorMessage,
  ErrorMessage2,
  FormBig,
  FormDiv,
  LoginButton,
  LoginInput,
  MainDiv,
  TextDiv,
} from "./styled";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

export const AdminLogin = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  useLayoutEffect(() => {
    localStorage.removeItem("admin");
  }, []);

  const loginAdmin = async (data) => {
    try {
      const request = await fetch("https://server.rsoft.am/admin/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      console.log(request);
      const response = await request.json();
      if (request.status !== 200) {
        alert(response.error);
      } else {
        localStorage.setItem("admin", response.token);
        navigate("/admin-page", { state: response });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <MainDiv onSubmit={handleSubmit(loginAdmin)}>
      <TextDiv>Welcome Admin</TextDiv>
      <FormBig>
        <FormDiv>
          <LoginInput
            placeholder="login"
            name="name"
            {...register("name", { required: "this field is required" })}
          />
          <ErrorMessage>{errors?.name?.message}</ErrorMessage>
          <LoginInput
            placeholder="password"
            name="password"
            {...register("password", { required: "this field is required" })}
          />
          <ErrorMessage2>{errors?.password?.message}</ErrorMessage2>
          <LoginButton>Sign In</LoginButton>
        </FormDiv>
      </FormBig>
    </MainDiv>
  );
};
