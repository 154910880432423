import React, { useEffect, useState } from "react";
import {
  LessonCard,
  LessonLogoPart,
  LessonsMain,
  MainDiv,
  LesonLogo,
  TextDiv,
  LessonNameDiv,
  LessonDesc,
  LessonsMain2,
  SelectedImg,
  PlaceImgDesc,
} from "./styled";
import { SelectedDesc } from "./styled";
import { TeacherPart } from "./styled";
import { ApplyButton } from "./styled";

export const AllSoonCourses = () => {
  const [courses, setCourses] = useState([]);
  const [selected, setSelected] = useState();

  useEffect(() => {
    async function getAllCourses() {
      try {
        const request = await fetch("https://server.rsoft.am/all-courses-soon");
        if (!request.ok) return;
        const response = await request.json();
        console.log(response, "jhgfd");
        setCourses(response.courses);
      } catch (err) {
        console.error(err);
      }
    }
    getAllCourses();
  }, []);

  const getCourseById = async (id) => {
    try {
      const req = await fetch(`https://server.rsoft.am/all-courses-soon/${id}`);
      const res = await req.json();
      if (req.ok) {
        setSelected(res);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <MainDiv>
      <TextDiv>{!selected ? "Սպասվող դասընթացները" : selected.name}</TextDiv>
      {!selected ? (
        <LessonsMain>
          {courses.length > 0 ? (
            courses.map((course, index) => (
              <LessonCard key={index} onClick={() => getCourseById(course._id)}>
                <LessonLogoPart>
                  <LesonLogo
                    src={`https://server.rsoft.am/uploads/${course.img}`}
                    width={"50%"}
                    height={"90%"}
                  />
                </LessonLogoPart>
                <LessonNameDiv>{course.name}</LessonNameDiv>
                <LessonDesc>{course.description}</LessonDesc>
              </LessonCard>
            ))
          ) : (
            <div>No courses available</div>
          )}
        </LessonsMain>
      ) : (
        <LessonsMain2>
          <PlaceImgDesc>
            <SelectedImg>
              <img
                src={`https://server.rsoft.am/uploads/${selected.img}`}
                width={"100%"}
                height={"100%"}
              />
            </SelectedImg>
            <SelectedDesc>
              {selected.description}
              <TeacherPart>
                <b>Մասնագետ՝</b> {selected.teacher}
              </TeacherPart>
              <TeacherPart>
                <b>Մեկնարկ՝</b> {selected.start}
              </TeacherPart>
              <TeacherPart>
                <b>Ամսեվճար`</b> {selected.price} Դ
              </TeacherPart>
              <ApplyButton>Դիմել</ApplyButton>
              <TeacherPart style={{color:"red" , cursor:"pointer"}} onClick={()=>setSelected(null)}>Տեսնել բոլորը</TeacherPart>
            </SelectedDesc>
          </PlaceImgDesc>
        </LessonsMain2>
      )}
    </MainDiv>
  );
};
