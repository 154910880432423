import React, { useState, useEffect } from "react";
import { Card, CH1, ContainerDiv, H2name, ImgBox, ImgL, PD } from "./styled";
import { Link } from "react-router-dom";

export const CoursesComp = () => {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    async function getAllCourses() {
      try {
        const request = await fetch("https://server.rsoft.am/all-courses");
        if (!request.ok) return;
        const response = await request.json();
        console.log(response, "courses");
        setCourses(response.courses);
      } catch (err) {
        console.error(err);
      }
    }
    getAllCourses();
  }, []);

  return (
    <>
      <ContainerDiv>
        <CH1>Մեր Դասընթացները</CH1>
        {courses?.map((e, i) => {
          return (
            <Card key={e + i} to={"/courses"}>
              <ImgBox>
                <ImgL src={`https://server.rsoft.am/uploads/${e.img}`} />
              </ImgBox>
              <PD>
                {e.description.slice(0, 191)}
                {e.description.length > 100 && "..."}
              </PD>
              <H2name>{e.name}</H2name>
            </Card>
          );
        })}
      </ContainerDiv>
      <br />
      <br />

    </>
  );
};
