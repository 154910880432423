import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import GlobalStyles from "./GlobalStyles";
import {
  Home,
  Testimonials,
  Contact,
  WebApp,
  Courses,
  ComingSoon,
} from "./containers";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./locales/en/translation.json";
import translationRU from "./locales/ru/translation.json";
import translationAM from "./locales/am/translation.json";
import { Footer, Menu } from "./components";
import { AdminLogin } from "./admin/admin_login";
import { AdminPage } from "./admin";
import {
  BackgroundAnimation,
  BackgroundGif,
} from "./animation_background/index";
import { ToastContainer } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';
i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: translationEN,
    },
    ru: {
      translation: translationRU,
    },
    am: {
      translation: translationAM,
    },
  },
  lng: localStorage.getItem("lang") || "ru",
  fallbackLng: "ru",
  interpolation: {
    escapeValue: false,
  },
});

export const Main = () => {
  const loc = useLocation();

  document.title = "R'SOFT";
  return (
    <>

      <GlobalStyles />

      {loc.pathname !== "/admin" && loc.pathname !== "/admin-page" ? (
        <Menu />
      ) : null}
      {loc.pathname !== "/admin" && loc.pathname !== "/admin-page" ? (
        <BackgroundGif backgroundColor="#0267c0" hoverColor="#0267c0" />
      ) : null}

      <div
        style={{
          position: "relative",
          top: `${loc.pathname !== "/admin" && loc.pathname !== "/admin-page"
            ? "60px"
            : "0"
            }`,
          zIndex: 9999999999999999999999999,
        }}
      >
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          style={{ zIndex: 9999999999, marginTop: "80px" }} // Применение zIndex через стиль
        />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/review" element={<Testimonials />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/web-app" element={<WebApp />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/admin" element={<AdminLogin />} />
          <Route path="/admin-page" element={<AdminPage />} />
          <Route path="/soon-course/:id" element={<ComingSoon />} />
        </Routes>
      </div>
    </>
  );
};
