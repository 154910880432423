import styled from "styled-components";

export const MainDiv = styled.div`
  width: 100%;
  height: 900px;
`;

export const TitlePart = styled.div`
  width: 100%;
  height: 120px;
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  padding-top: 55px;
  color: white;
  @media (max-width: 482px) {
    font-size: 22px;
  }
`;

export const LessonsMain = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
`;

export const LessonCard = styled.div`
  width: 80%;
  height: 350px;
  background-color: #cae3ff61;
  border-radius: 20px;
  display: flex;
  overflow: hidden;
  transition: 0.7s;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
      rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
      rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  }
  @media (max-width: 1200px) {
    min-height: 410px;
  }
  @media (max-width: 800px) {
    flex-direction: column;
    min-height: 650px;
  }

  @media (max-width: 650px) {
    flex-direction: column;
    min-height: 650px;
  }
 
`;

export const ImagePart = styled.div`
  width: 45%;
  height: 260px;
  margin: 50px;
  float: left;
  border-radius: 20px;
  background-color: blue;
  overflow: hidden;
  @media (max-width: 800px) {
    width: 90%;
    margin:  10px auto 0 auto;
  height: 200px;
  border-radius: 20px;
  }
`;

export const RightPart = styled.div`
  width: 60%;
  height: 350px;
  @media (max-width: 800px) {
    width: 90%;
    margin: 20px auto;
  }
`;

export const H2name = styled.h1`
  width: 100%;
  height: 60px;
  text-align: center;
  filter: brightness(120%);
  color: white;
  padding-top: 20px;
  @media (max-width: 830px) {
    font-size: 27px;
  }
  @media (max-width: 480px) {
    height: 40px;
    padding-top: 0;
  }

  @media (max-width: 385px) {
    font-size: 20px;
  }
`;

export const DescriptionDIv = styled.div`
  width: 90%;
  min-height: 110px;
  margin: 15px auto;
  font-size: 13.5px;
  color: white;

  @media (max-width: 992px) {
    font-size: 13px;
  }
  @media (max-width: 420px) {
    font-size: 12.5px;
    margin: 5px auto;
  }
`;

export const PriceAndDuration = styled.div`
  width: 90%;
  height: 25px;
  margin: 12px auto;
  color: white;
 
  @media (max-width: 910px) {
    padding-top: 10px;
    margin: 0px auto;
  }
  @media (max-width: 450px) {
    padding-top: 10px;
  }
  @media (max-width: 420px) {
    padding-top: 15px;
  }
`;

export const ApplyBtn = styled.button`
  cursor: pointer;
  width: 90%;
  height: 35px;
  border-radius: 20px;
  background-color: white;
  color: #296ebd;
  border: none;
  outline: none;
  margin-left: 25px;
  margin-top: 20px;
  font-size: 17px;
  font-weight: bold;
  transition: 0.7s;
 
  @media (max-width: 800px) {
    margin-top: 30px;
  }

  @media (max-width: 470px) {
    margin-left: 15px;
  }
`;

export const OpenedDiv = styled.div`
  width: 100%;
  height: 100vh;
  background: #0000005e;
  position: fixed;
  display: flex;
  padding-top: 10px;
  justify-content: center;
  z-index: 999;
`;

export const ApplyBlankDiv = styled.div`
  width: 40%;
  height: 87vh;
  background-color: white;
  border-radius: 20px;
  z-index: 999;
  overflow: hidden;
  @media (max-width: 1010px) {
    width: 60%;
  }
  @media (max-width: 698px) {
    width: 80%;
  }
  @media (max-width: 522px) {
    width: 90%;
  }
`;

export const TtitleBlank = styled.div`
  width: 100%;
  height: 50px;
  padding: 15px;
  font-weight: bold;
  font-size: 17px;
  color: #296ebd;
`;

export const CloseBlank = styled.div`
  width: 30px;
  height: 30px;
  float: right;
  font-weight: bold;
  cursor: pointer;
  font-size: 27px;
  color: black;
`;

export const InfoParts = styled.div`
  width: 100%;
  height: 75px;
`;

export const InfoName = styled.div`
  width: 100%;
  height: 30px;
  font-weight: bold;
  padding-left: 15px;
  padding-top: 5px;
`;

export const InfoInp = styled.input`
  width: 94%;
  height: 35px;
  border-radius: 10px;
  border: 3px solid #296ebd;
  margin-left: 15px;
  margin-top: 5px;
  outline: none;
  padding-left: 10px;
`;

export const FinishApply = styled.button`
  border: none;
  outline: none;
  width: 94%;
  height: 40px;
  background-color: #296ebd;
  margin-left: 15px;
  color: white;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 20px;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 1px;
`;
