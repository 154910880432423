import React from "react";
import {
  AddBtn,
  DescriptionInput,
  FileBtn,
  FileInput,
  FormOfInputs,
  FormPart,
  InputInfo,
  MainDiv,
  NameInput,
  TextDiv,
  TreePArts,
} from "./styled";
import { useForm } from "react-hook-form";

export const AddSoonCourse = () => {
  const { register, handleSubmit, formState } = useForm();

  const onProductsSubmit = async (data) => {
    if (
      data.name === "" ||
      data.description === "" ||
      data.price === "" ||
      data.duration === ""
    ) {
      alert("Please fill in all fields");
      return;
    }
    console.log(data);
    if (!data.img[0]) {
      alert("No image selected");
      return;
    }
    if (data.img[0]?.size > 10 * 1024 * 1024) {
      alert("Image must be smaller than 10MB");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("price", data.price);
      formData.append("duration", data.duration);
      formData.append("description", data.description);
      formData.append("teacher", data.teacher);
      formData.append("start", data.start);
      formData.append("img", data.img[0]);

      const req = await fetch("https://server.rsoft.am/add-new-course-soon", {
        method: "POST",
        body: formData,
      });

      if (req.ok) {
        alert("Course added successfully");
      } else {
        alert("Something went wrong");
      }

      const res = await req.json();
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <MainDiv
      onSubmit={handleSubmit(onProductsSubmit)}
      enctype="multipart/form-data"
    >
      <TextDiv>Add New Soon Course</TextDiv>
      <FormPart>
        <FormOfInputs>
          <TreePArts>
            <InputInfo>Name</InputInfo>
            <NameInput {...register("name")} />
          </TreePArts>
          <TreePArts>
            <InputInfo>Price</InputInfo>
            <NameInput {...register("price")} />
          </TreePArts>
          <TreePArts>
            <InputInfo>Duration</InputInfo>
            <NameInput {...register("duration")} />
          </TreePArts>
          <TreePArts>
            <InputInfo>Teacher</InputInfo>
            <NameInput {...register("teacher")} />
          </TreePArts>
          <TreePArts>
            <InputInfo>Start</InputInfo>
            <NameInput {...register("start")} />
          </TreePArts>
        </FormOfInputs>

        <InputInfo style={{ paddingLeft: "12px" }}>Description</InputInfo>
        <DescriptionInput {...register("description")} />
        <FileBtn>Select Image</FileBtn>
        <FileInput type="file" accept="image/*" {...register("img")} />
        <AddBtn>Add Course</AddBtn>
      </FormPart>
    </MainDiv>
  );
};
