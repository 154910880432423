import React, { useEffect, useState } from "react";
import {
  MainDiv,
  TitlePart,
  LessonsMain,
  LessonCard,
  ImagePart,
  RightPart,
  H2name,
  DescriptionDIv,
  PriceAndDuration,
  ApplyBtn,
  OpenedDiv,
  ApplyBlankDiv,
  TtitleBlank,
  CloseBlank,
  InfoParts,
  InfoName,
  InfoInp,
  FinishApply,
} from "./styled";
import { IoCloseCircle } from "react-icons/io5";
import { toast } from "react-toastify";
export const Courses = () => {
  const [courses, setCourses] = useState([]);
  const [apply, setApply] = useState(false);
  const [docs, setDocs] = useState({
    anun: "",
    azganun: "",
    phone: "",
    selectedCourse: "",
    age: "",
  });

  useEffect(() => {
    async function getAllCourses() {
      try {
        const request = await fetch("https://server.rsoft.am/all-courses");
        if (!request.ok) return;
        const response = await request.json();
        console.log(response, "asdsa");
        setCourses(response.courses);
      } catch (err) {
        console.error(err);
      }
    }
    getAllCourses();
  }, []);

  const myChange = (e) => {
    const { value, name } = e.target;
    setDocs((prev) => {
      return { ...prev, [name]: value };
    });
  };
  const sendData = async () => {
    if (docs.anun == "" || docs.azganun == "" || docs.phone == "" || docs.age == "") {
      return toast.warning("Լրացնել բաց թողնված դաշտը")
    }else{
      toast.success("Դուք հաջողությամբ գրանցվել եք")
      setApply(false)
      setDocs({
        anun: "",
        azganun: "",
        phone: "",
        age: "",
      });
    }
    
    try {
      const req = await fetch("https://server.rsoft.am/get-students", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(docs),
      });
      const res = await req.json();
      console.log(res);
      if (req.ok) {
       


      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <MainDiv style={{ position: "relative" }}>
      {apply && (
        <OpenedDiv>
          <ApplyBlankDiv>
            <TtitleBlank>
              Լրացրեք տվյալները գրանցվելու համար{" "}
              <CloseBlank>
                <IoCloseCircle onClick={() => setApply(false)} />
              </CloseBlank>
            </TtitleBlank>
            <InfoParts>
              <InfoName>
                Անուն <span style={{ fontSize: "21px", color: "red" }}>*</span>
              </InfoName>
              <InfoInp name="anun" value={docs.anun} onChange={myChange} />
            </InfoParts>
            <InfoParts>
              <InfoName>
                Ազգանուն{" "}
                <span style={{ fontSize: "21px", color: "red" }}>*</span>
              </InfoName>
              <InfoInp
                name="azganun"
                value={docs.azganun}
                onChange={myChange}
              />
            </InfoParts>
            <InfoParts>
              <InfoName>
                Հեռախոսահամար{" "}
                <span style={{ fontSize: "21px", color: "red" }}>*</span>
              </InfoName>
              <InfoInp
                type="number"
                name="phone"
                value={docs.phone}
                onChange={myChange}
              />
            </InfoParts>
            <InfoParts>
              <InfoName>
                Տարիք <span style={{ fontSize: "21px", color: "red" }}>*</span>
              </InfoName>
              <InfoInp
                type="number"
                name="age"
                value={docs.age}
                onChange={myChange}
              />
            </InfoParts>
            <InfoParts>
              <InfoName>
                Ընտրած դասընթաց{" "}
                <span style={{ fontSize: "21px", color: "red" }}>*</span>
              </InfoName>
              <InfoInp value={docs.selectedCourse} />
            </InfoParts>

            <FinishApply onClick={sendData}>Դիմել</FinishApply>
          </ApplyBlankDiv>
        </OpenedDiv>
      )}

      <TitlePart>ՄԵՐ ԴԱՍԸՆԹԱՑՆԵՐԸ</TitlePart>
      <br />
      <br />

      <LessonsMain>
        {courses?.map((e, i) => {
          return (
            <LessonCard key={e + i} i={i}> 
              <ImagePart>
                <img
                  src={`https://server.rsoft.am/uploads/${e.img}`}
                  width={"100%"}
                  height={"100%"}
                />
              </ImagePart>
              <RightPart>
                <H2name>{e.name}</H2name>
                <DescriptionDIv>{e.description}</DescriptionDIv>
                <PriceAndDuration>
                  Ամսեվճար՝ <b>{e.price}Դ</b>
                </PriceAndDuration>
                <PriceAndDuration>
                  Տևողություն՝ <b>{e.duration}</b>
                </PriceAndDuration>
                <ApplyBtn
                  onClick={() => {
                    setApply(true);
                    setDocs((prev) => {
                      return { ...prev, selectedCourse: e.name };
                    });
                  }}
                >
                  Դիմել
                </ApplyBtn>
              </RightPart>
            </LessonCard>
          );
        })}
      </LessonsMain>
      <br />
      <br />
    </MainDiv>
  );
};
