import styled from "styled-components";

export const MainDiv = styled.div`
  width: 100%;
`;

export const TextDiv = styled.div`
  width: 100%;
  height: 10vh;
  text-align: center;
  font-size: 38px;
  font-weight: bold;
  color: #24b7ff;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

export const LessonsMain = styled.div`
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
`;

export const LessonCard = styled.div`
  width: 30%;
  height: 210px;
  border-radius: 10px;
  border: 2px solid #24b7ff;
  overflow: hidden;
  cursor: pointer;
  transition: 0.5s;
  &:hover {
    background-color: #24b7ff;
  }
`;

export const LessonLogoPart = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LesonLogo = styled.img`
  margin: auto;
`;

export const LessonNameDiv = styled.div`
  width: 100%;
  height: 45px;
  font-weight: bold;
  font-size: 19px;
  text-align: center;
  padding-top: 8px;
`;

export const LessonDesc = styled.div`
  width: 100%;
  height: 18px;
  text-align: center;
  font-size: 12px;
`;
