import React, { useEffect, useState } from "react";
import people from "./data";
import { FaChevronLeft, FaChevronRight, FaQuoteRight } from "react-icons/fa";
import userimg from "../../assets/images/user.png";

const Review = () => {
  const [index, setIndex] = useState(0);
  const [people2, setPeaople] = useState();

  useEffect(() => {
    async function getReviews() {
      try {
        const request = await fetch("https://server.rsoft.am/reviewsAll");
        const res = await request.json();
        setPeaople(res);
      } catch (err) {
        console.log(err);
      }
    }
    getReviews();
  }, []);

  const checkNumber = (number) => {
    if (number > people.length - 1) {
      return 0;
    } else if (number < 0) {
      return people.length - 1;
    }
    return number;
  };

  const nextPerson = () => {
    setIndex((index) => {
      if (index < people2.length - 1) {
        var newIndex = index + 1;
      } else {
        return index;
      }
      return checkNumber(newIndex);
    });
  };

  const prevPerson = () => {
    setIndex((index) => {
      if (index > 0) {
        var newIndex = index - 1;
      } else {
        return index;
      }
      return checkNumber(newIndex);
    });
  };

  return people2 ? (
    <article className="review">
      <div className="img-container">
        <img src={userimg} className="person-img" />
        <span className="quote-icon">
          <FaQuoteRight />
        </span>
      </div>
      <h4 className="author">{people2 && people2[index]?.name}</h4>
      <p className="jon">{people2 && people2[index]?.job}</p>
      <p className="info">{people2 && people2[index]?.text}</p>
      <div className="button-container">
        <button className="prev-btn" onClick={prevPerson}>
          <FaChevronLeft />
        </button>
        <button className="next-btn" onClick={nextPerson}>
          <FaChevronRight />
        </button>
      </div>
    </article>
  ) : (
    <div>karciq chka</div>
  );
};

export default Review;
