import styled from "styled-components";

export const MainDiv = styled.div`
  width: 100%;
  height: 100vh;
`;

export const TitlePart = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 40px;
  color: #296ebd;
  padding-top: 10px;
`;

export const MoreDiv = styled.div`
  width: 100%;
  height: 480px;
  display: flex;
  flex-wrap: wrap;
`;

export const Halfs = styled.div`
  width: 50%;
  height: 480px;
`;

export const DetailPart = styled.div`
  width: 100%;
  height: 70px;
  text-align: center;
  padding-top: 15px;
  font-size: 27px;
  font-weight: bold;
`;

export const Detailings = styled.div`
  width: 100%;
  height: 30px;
  padding-left: 10px;
  padding-top: 5px;
  margin-top: 5px;
`;

export const Apply = styled.button`
  border: none;
  outline: none;
  width: 110px;
  height: 40px;
  background-color: #296ebd;
  margin-left: 10px;
  margin-top: 25px;
  color: white;
  cursor: pointer;
`;

export const OpenedDiv = styled.div`
  width: 100%;
  height: 100vh;
  background: #0000005e;
  position: absolute;
  display: flex;
  justify-content: center;
  padding-top: 50px;
`;

export const ApplyBlankDiv = styled.div`
  width: 40%;
  height: 80vh;
  background-color: white;
  border-radius: 20px;
  z-index: 999;
  overflow: hidden;
`;

export const TtitleBlank = styled.div`
  width: 100%;
  height: 50px;
  padding: 15px;
  font-weight: bold;
  font-size: 17px;
  color: #296ebd;
`;

export const CloseBlank = styled.div`
  width: 30px;
  height: 30px;
  float: right;
  font-weight: bold;
  cursor: pointer;
  font-size: 27px;
  color: black;
`;

export const InfoParts = styled.div`
  width: 100%;
  height: 75px;
`;

export const InfoName = styled.div`
  width: 100%;
  height: 30px;
  font-weight: bold;
  padding-left: 15px;
  padding-top: 5px;
`;

export const InfoInp = styled.input`
  width: 94%;
  height: 35px;
  border-radius: 10px;
  border: 3px solid #296ebd;
  margin-left: 15px;
  margin-top: 5px;
  outline: none;
  padding-left: 10px;
`;

export const FinishApply = styled.button`
  border: none;
  outline: none;
  width: 94%;
  height: 40px;
  background-color: #296ebd;
  margin-left: 15px;
  color: white;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 20px;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 1px;
`;
