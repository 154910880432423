import React from "react";
import {
  AddBtn,
  DescriptionInput,
  FileBtn,
  FileInput,
  FormPart,
  InputInfo,
  MainDiv,
  NameInput,
  TextDiv,
} from "./styled";
import { useForm } from "react-hook-form";

export const AddService = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onProductsSubmit = async (data) => {
    if (data.name === "" || data.description === "") {
      console.log("Er");
      alert("լրացնել բոլոր դաշտերը");
      return;
    }
    if (!data.img[0]) {
      alert("նկարը ընտրված չէ!");
      return;
    }
    if (data.img[0]?.size > 10 * 1024 * 1024) {
      alert("նկարը 10 մեգաբայթից թեթև պետք է լինի");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("description", data.description);
      formData.append("img", data.img[0]);

      const req = await fetch("https://server.rsoft.am/addService", {
        method: "POST",
        body: formData,
      });

      if (req.ok) {
        alert("ծառայությունը ավելացավ");
      } else {
        alert("մի բան այն չէ");
      }

      const res = await req.json();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <MainDiv onSubmit={handleSubmit(onProductsSubmit)}>
      <TextDiv>Ավելացրեք նոր ծառայություն</TextDiv>
      <FormPart>
        <InputInfo>Անվանում</InputInfo>
        <NameInput {...register("name")} />
        <InputInfo>Նկարագրություն</InputInfo>
        <DescriptionInput {...register("description")} />
        <FileBtn>Ընտրեք նկարը</FileBtn>
        <FileInput type="file" accept="image/*" {...register("img")} />
        <AddBtn>Ավելացնել ծառայությունը</AddBtn>
      </FormPart>
    </MainDiv>
  );
};
