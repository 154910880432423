import styled from "styled-components";

export const MainDiv = styled.div`
  width: 100%;
  height: 100vh;
`;

export const TextDiv = styled.div`
  width: 100%;
  height: 10vh;
  text-align: center;
  font-size: 38px;
  font-weight: bold;
  color: #24b7ff;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

export const FormPart = styled.form`
  width: 75%;
  height: 72vh;
  border: 2px solid #24b7ff;
  margin: 50px auto;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
`;

export const InputInfo = styled.div`
  width: 100%;
  height: 40px;
  padding-left: 7px;
  padding-top: 10px;
  font-weight: bold;
  font-size: 16px;
`;

export const NameInput = styled.input`
  width: 97%;
  height: 35px;
  border-radius: 10px;
  border: 2px solid #24b7ff;
  outline: #24b7ff;
  margin-left: 5px;
  padding-left: 10px;
  background: none;
`;

export const DescriptionInput = styled.textarea`
  width: 97%;
  height: 100px;
  border-radius: 10px;
  border: 2px solid #24b7ff;
  outline: #24b7ff;
  margin-left: 10px;
  padding-left: 10px;
  background: none;
  padding-top: 10px;
  resize: none;
`;

export const FileBtn = styled.div`
  width: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  height: 40px;
  border-radius: 10px;
  color: #24b7ff;
  border: 2px solid #24b7ff;
  cursor: pointer;
`;

export const FileInput = styled.input`
  width: 140px;
  background-color: lime;
  position: absolute;
  top: 270px;
  left: 10px;
  height: 40px;
  border-radius: 10px;
  opacity: 0;
  cursor: pointer;
`;

export const AddBtn = styled.button`
  width: 97%;
  height: 45px;
  background-color: #24b7ff;
  border-radius: 10px;
  color: white;
  font-weight: bold;
  margin: 20px 10px;
  border: none;
  outline: none;
  cursor: pointer;
`;

export const FormOfInputs = styled.div`
  width: 100%;
  height: 90px;
  display: flex;
`;

export const TreePArts = styled.div`
  width: 33.3%;
  height: 90px;
`;
