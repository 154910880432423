import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Main } from "./Main";


export const App = () => {
  document.title = "R'SOFT";
  return (
    <Router>
      <Main />
    </Router>
  );
};
