import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  MainMenu,
  LogoDiv,
  SpanR,
  Departments,
  DepartmentsContainer,
  IconDiv,
  RespMenu,
  DepartmentsResp,
} from "./styled";
import { IoMdMenu } from "react-icons/io";

export const Menu = () => {
  const [pages, setPages] = useState([
    { inner: "Գլխավոր", path: "/", choosen: true },
    { inner: "Դասընթացներ", path: "/courses", choosen: false },
    { inner: "Վեբ կայքեր", path: "/web-app", choosen: false },
    { inner: "Կոնտակտ", path: "/contact", choosen: false },
  ]);
  const [openMenu, setOpenMenu] = useState(false);
  const location = useLocation();

  const menuColor = (e, i) => {
    setOpenMenu(false);
    let x = pages.slice();
    x.map((element, indexes) => {
      if (indexes === i) element.choosen = true;
      else element.choosen = false;
    });
    setPages(x);
  };

  useEffect(() => {
    let x = pages.slice();
    x.map((element) => {
      if (element.path === location.pathname) element.choosen = true;
      else element.choosen = false;
    });
    setPages(x);
  }, [location.pathname]);

  return (
    <>
      <RespMenu value={openMenu}>
        {pages.map((element, index) => {
          return (
            <DepartmentsResp
              key={index}
              to={element.path}
              $active={element.choosen}
              onClick={() => menuColor(element, index)}
            >
              {element.inner}
            </DepartmentsResp>
          );
        })}
      </RespMenu>
      <MainMenu>
        <LogoDiv to={"/"}>
          <SpanR>R</SpanR>'SOFT
        </LogoDiv>
        <DepartmentsContainer>
          {pages.map((element, index) => {
            return (
              <Departments
                key={index}
                to={element.path}
                $active={element.choosen}
                onClick={() => menuColor(element, index)}
              >
                {element.inner}
              </Departments>
            );
          })}
        </DepartmentsContainer>
        <IconDiv onClick={() => setOpenMenu((e) => !e)}>
          <IoMdMenu />
        </IconDiv>
      </MainMenu>
    </>
  );
};
