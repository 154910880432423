import styled from "styled-components";

export const MainDiv = styled.div`
  width: 100%;
  height: 100vh;
`;

export const CommandDiv = styled.div`
  width: 100%;
  height: 50px;
  padding: 13px 10px;
  color: white;
  font-size: 15px;
  font-weight: bold;
  background-color: ${({ checked }) => (checked ? "white" : "#24b7ff")};
  color: ${({ checked }) => (checked ? "#24b7ff" : "white")};
  cursor: pointer;
  &:hover {
    background-color: white;
    color: #24b7ff;
  }
`;
